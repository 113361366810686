.image-part{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    img{
        width: 95%;
        height: 98%;
        display: block;
        margin: auto;
        border-radius: 10px;
        opacity: .7;
    }
}

section{
    width: 50%;
    background: rgb(235, 234, 234);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .link-to{
        text-decoration: none;
        button{
            display: block;
            width: 300px;
            padding: 30px 0;
            cursor: pointer;
            font-size: 30px;
            border-radius: 10px;
            margin-left: 30px;
            background: #fff;
            i{
                font-size: 35px;
            }
        }
    }
    .curriculum-btn{
        @include section-btns(#D65745);
    }
    .timetable-btn{
        @include section-btns(#8448A8)
    }
    .attendance-btn{
        @include section-btns(#4A9E86);
    }
    .announcement-btn{
        @include section-btns(#437EB4);
    }
    
}

main{
    display: flex;
    height: calc(100vh - 116.8px);
    background: rgb(235, 234, 234);
}