.login-body{
    width: 100%;
    height: 100vh;
    //background: rgb(232,68,121);
    //background: linear-gradient(35deg, rgba(232,68,121,1) 48%, rgba(104,66,119,1) 74%);
    //background: url('../img/ufaz-background.jpg') no-repeat;
    
    background: linear-gradient(
        rgba(97, 93, 93, 0.7), 
        rgba(97, 93, 93, 0.7)
      ), url('../img/ufaz-background.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .container{
        background: #fff;
        text-align: center;
        padding: 30px 0;
        border-radius: 20px;
        width: 40%;
        h2{
            color: #a0a0a0;
            font-size: 35px;
        }
        .inputs{
            margin: 20px 0;
            label{
                color: #5a5959;
            }
            input{
                width: 30%;
                background: #eeeded;
                border: none;
                height: 30px;
                outline: none;
                padding: 15px;
                border-radius: 5px;
            }

        }
        .submit-btn{
            padding: 10px 40px;
            border: none;
            font-size: 16px;
            font-weight: bolder;
            border-radius: 5px;
            background: #4F71D2;
            color: #fff;
            cursor: pointer;
            margin-bottom: 10px;
            transition: .5s;
            &:hover{
                color: #4F71D2;
                background: #e7e5e5;
            }
        }
        .link-to{
            font-size: 12px;
            color: blue;
        }
    }
}

.error-message{
    color: red;
    text-transform: capitalize;
    text-align: center;
}