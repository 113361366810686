nav{
    background: #5173ed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    .title{
        width: 30%;
        h1{
            font-size: 35px;
            .link-to{
                text-decoration: none;
                color: #fff;
            }
        }
    }
    .links-to-pages{
        width: 60%;
        ul{
            display: flex;
            justify-content: space-around;
            list-style: none;
            align-items: center;
            .visible{
                display: block;
            }
            .unvisible{
                visibility: hidden;
            }
            li{
                font-size: 18px;
                .link-to{
                    text-decoration: none;
                    color: #171d29;
                    font-weight: bolder;
                    transition: .3s;
                    &:hover{
                        color: #fff;
                    }
                }
                .active{
                    color: #fff;
                }
            }
            .user-info{
                color: #5173ed;
                font-weight: bolder;
                background: #fff;
                font-size: 24px;
                padding: 20px;
                border-radius: 50%;
                border: 3px solid #fff;
                cursor: pointer;
            }
        }
    }
}