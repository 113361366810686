.admin-teacher{
    height: 100vh;
    background: #d8d9eb;
    .container{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        margin-top: 5%;
        .container-form{
            background: #fff;
            height: 300px;
            width: 30%;
            margin-top: 20px;
            padding: 10px 0;
            border-radius: 10px;
            h3{
                text-align: center;
                padding-bottom: 15px;
            }
            input{
                width: 60%;
                padding: 5px 10px;
                outline: none;
                display: block;
                margin: 10px auto;
                border: 2px solid rgb(134, 134, 134);
                border-radius: 5px;
            }
            .register-btn{
                font-size: 18px;
                text-transform: capitalize;
                width: unset;
                padding: 5px 25px;
                cursor: pointer;
                background: rgb(2, 184, 2);
                color: #fff;
                transition: .3s;
                border: none;
                &:hover{
                    background: rgb(1, 125, 1);
                }
            }
        }
        .container-table{
            width: 60%;
            table{
                display: block;
                border-collapse: collapse;
                width: 70%;
                border-radius: 10px;
                max-height: 500px;
                overflow-y: scroll;
                th {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    background-color: #5173ed;
                    color: #fff;
                }
                th,td{
                    padding: 8px;
                    text-align: center;
                }
                th:last-child{
                    border-top-right-radius: 10px;
                }
                td{
                    width: 35%;
                }
                .tr-tbody:last-child{
                    td:first-child{
                        border-bottom-left-radius: 10px;
                    }
                    td:last-child{
                        border-bottom-right-radius: 10px;
                    }
                }
                .tr-tbody:nth-child(even){
                    background: #f2f2f2;
                }
                .tr-tbody:nth-child(odd){
                    background: #fff;
                }

                .tr-tbody{
                    &:hover{
                        background: #ddd;
                    }
                }
                .td-btn{
                    button{
                        font-size: 18px;
                        color: #fff;
                        padding: 5px 10px;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: .3s;
                        background: red;
                        &:hover{
                            background: rgb(170, 2, 2);
                        }
                    }
                }
                .edit-btn{
                    background: orange;
                    font-size: 18px;
                    color: #fff;
                    padding: 5px 10px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: .3s;
                     &:hover{
                        background: rgb(180, 118, 3);
                    }
                }
            }
        }
    }
}