@import 'mixin';
@import 'login';
@import 'register';
@import 'home';
@import 'subject';
@import 'adminSubject';
@import 'adminLesson';
@import 'adminGroup';
@import 'navbar';
@import 'adminTeacher';
@import 'adminAnnounce';
@import 'adminStudent';
@import 'adminHome';
@import 'timetable';
@import 'curriculum';
@import 'attendance';
@import 'announcement';
@import 'adminAbsence';

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}