.admin-subject{
    background: #d8d9e5;
    height: 100vh;
    h3{
        font-size: 24px;
        text-align: center;
        color: #5173ed;
    }
    form{
        margin-top: 30px;
        input{
            display: block;
            margin: 5px auto;
            padding: 5px 7px;
            outline: none;
            border: none;
            border-radius: 5px;
            width: 60%;
            border: 2px solid #5173ed;
        }
        .add-btn{
            border: none;
            width: 15%;
            font-size: 16px;
            color: #fff;
            text-transform: capitalize;
            background: rgb(2, 184, 2);
            padding: 10px 15px;
            cursor: pointer;
            transition: .3s;
            &:hover{
                background: rgb(1, 125, 1);
            }
        }
    }
    .td-center{
        text-align: center;
    }
    .content{
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
        .table-container{
            width: 60%;
            height: 600px;
            overflow: scroll;
            border-radius: 10px;
        }
        .form-container{
            width: 35%;
            padding-top: 30px;
            background: #fff;
            height: 320px;
            border-radius: 10px;
        }
    }
    table{
        background: #fff;
        width: 100%;
        border-collapse: collapse;
        border-radius: 10px;
        max-height: 100%;
        th,td{
            padding: 8px;
            text-align: center;
        }
        th {
            position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
            position: sticky;
            top: 0;
            z-index: 1; // any positive value, layer order is global
            // any bg-color to overlap
        }
        .th-top-first{
            border-top-left-radius: 10px;
        }
        .th-top-last{
            border-top-right-radius: 10px;
        }
        th{
            padding-top: 12px;
            padding-bottom: 12px;
            background-color: #5173ed;
            color: #fff;
        }
        .tr-tbody:nth-child(even){
            background: #f2f2f2;
        }
        .tr-tbody{
            &:hover{
                background: #ddd;
            }
        }
        button{
            font-size: 18px;
            color: #fff;
            padding: 5px 10px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: .3s;
        }
        .delete-btn{
            background: red;
            &:hover{
                background: rgb(170, 2, 2);
            }
        }
        .edit-btn{
            background: orange;
            &:hover{
                background: rgb(180, 118, 3);
            }
        }
    }
    .edit-popup{
        border: 2px solid red;
    }
}

.popup-content {
    display: block;
    text-align: center;
    margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
    border: 2px solid #5173ed;
    border-radius: 10px;
    input{
        width: 200px;
        margin: 5px 0;
        padding: 5px;
    }
    .btn{
        display: block;
        margin: 0 auto;
        width: unset;
        padding: 8px 20px;
        color: red;
        text-transform: capitalize;
        cursor: pointer;
        border: none;
        background: #5173ed;
        color: #fff;
        font-size: 16px;
        font-weight: bolder;
        border-radius: 10px;
        transition: .3s;
        margin-bottom: 30px;
        &:hover{
            background: #0f33b2;
        }
    }
    
  }
  .popup-arrow {
    color: #5173ed;
  }
  [role='tooltip'].popup-content {
    width: 300px;
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }